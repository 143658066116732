<template>

    <span>
      <v-layout mb-4>
        <v-flex xs10 ml-3>
          <h2 class="lime--text lighten-1 pt-3">Gerenciar Imagens - {{ banner.bnnr_nome }}</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
          <v-btn
            color="blue-grey"
            class="black--text"
            :to="{name: 'bannerphotos', params: {banner_id: banner.bnnr_id}}"
          >
            <v-icon dark>keyboard_arrow_left</v-icon>
            Voltar
          </v-btn>
        </v-flex>
      </v-layout>

      <galeria-imagem-banner v-on:atualiza="loadBannerWithPhotos" :banner="banner"></galeria-imagem-banner>

    </span>
        
</template>

<script>
import GaleriaImagemBannerComponent from "./partial/GaleriaImagemBannerComponent";

export default {
  name: "AddImagemBannerComponent",
  created() {
    this.loadBannerWithPhotos()
  },
  props: {
      bnnr_id: {
        require: true
      }
  },
  data () {
    return {
      banner: {},
      btnLabel: 'Salvar',
    }
  },
  methods: {
    loadBannerWithPhotos() {
       console.log('passou aqui B')
      this.$store.dispatch('loadBannerWithPhotos', this.bnnr_id)
        .then(response => this.banner = response)
        .catch(error => {
          this.$swal({
            position: "center",
            type: "error",
            title: 'Erro',
            text: 'Dados não localizado',
            showConfirmButton: true,
            timer: 6000
          });
        })
    },
  },
  components: {
    GaleriaImagemBanner: GaleriaImagemBannerComponent,
  }
};
</script>

<style scoped>

</style>

<template>

        <span>

          <form-imagem-banner v-on:atualizar="atualizarBanners" :banner="banner" ></form-imagem-banner>

          <v-container fluid>
            <v-layout row wrap>

                    <v-flex xs6 sm6 md4 lg3 xl3 v-for="(imagem, index) in banner.images" :key="index" pa-2>
                      <v-card>
                        <v-img
                          class="img-preview"
                          :src="`${base_url}upload/banner_photo/${imagem.bnnr_ft_foto}`">
                        </v-img>

                        <v-card-actions class="justify-center">
                          <v-btn icon>
                            <v-icon @click.prevent="destroyBannerPhoto(imagem, index)">highlight_off</v-icon>
                          </v-btn>
                        </v-card-actions>

                      </v-card>

                    </v-flex>

                </v-layout>
          </v-container>
        
        </span>

</template>

<script>
import {URL_BASE} from '../../../../config/configs'
import FormImagemBannerComponent from "./FormImagemBannerComponent";

export default {
    name: "GaleriaImagemBannerComponent",
    props: {
        banner: {
        require: false,
        type: Object|Array,
        default: () => {
            return {
                bnnr_id: '',
                bnnr_nome: '',
            }
        },
        },
        require: true,
        meu_id: ''
    },

  data () {
      return {
        bannerPhoto: {
        require: false,
        type: Object|Array,
        default: () => {
          return {
            bnnr_ft_id: '',
            banner_id: '',
            bnnr_ft_link: '',
            bnnr_ft_link_target: '',
            bnnr_ft_descricao: '',
            bnnr_ft_foto: '',
            bnnr_ft_icone: '',
            bnnr_ft_click: '',
            bnnr_ft_ordem: '',
            bnnr_ft_status: '',
          }
        } 
      },
        base_url: URL_BASE
      }
  },

  methods: {
    atualizarBanners() {
        this.$emit('atualiza')
    },
    loadBannerWithPhotos() {
      this.$store.dispatch('loadBannerWithPhotos', this.banner.bnnr_id)
        .then(response => this.banner = response)
        .catch(error => {
          this.$swal({
            position: "center",
            icon: "error",
            title: 'Erro',
            text: 'Dados não localizado',
            showConfirmButton: true,
            timer: 6000
          });
        })
    },
    destroyBannerPhoto(item, index){
      this.$swal({
        title: 'Você tem certeza?',
        text: "Você não poderá reverter isso",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#D32F2F',
        cancelButtonColor: '#1976D2',
        confirmButtonText: 'Sim, Exclua!'
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('destroyBannerPhoto', item.bnnr_ft_id)
            .then(response => {
              if (response){
                this.banner.images.splice(index, 1);
              }else{
                this.$swal({
                position: "center",
                icon: "error",
                title: 'Opssss!',
                text: 'Erro ao excluir dados',
                showConfirmButton: true,
                timer: 6000
              });
              }
            })
            .catch(error => {
              this.$swal({
                position: "center",
                icon: "error",
                title: 'Opssss!',
                text: 'Algo errado aconteceu!',
                showConfirmButton: true,
                timer: 6000
              });
            })
        }
      })
    },
  },
  components: {
      formImagemBanner: FormImagemBannerComponent,
  }
};
</script>

<style scoped>

.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload, .btn-salvar{
  margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
}
</style>

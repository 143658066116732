<template>
<span>

    <v-form ref="form" lazy-validation autocomplete="off">
        <v-container fluid pl-0>

            <v-layout row justify-center>
                <v-flex xs2>
                    <input type="file" ref="files" multiple style="display:none;" id="files" @change="onFilesChange" />
                    <v-btn color="blue-grey" class="btn-upload white--text" @click="$refs.files.click()">
                        Adicionar Imagem
                        <v-icon right dark>cloud_upload</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>

        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    created() {

    },
    props: {
        banner: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    bnnr_id: '',
                    bnnr_nome: '',
                }
            },
        },
        require: true,
    },
    name: "FormImagemBannerComponent",
    data() {
        return {
            attachments: [],
            attachment: '',
            preview: null,
            base_url: URL_BASE,
            banner_id: ''
        }
    },
    methods: {

        onFilesSubmit() {

            const formData = new FormData();

            for (let i = 0; i < this.attachments.length; i++) {
                formData.append('imagens[]', this.attachments[i]);
            }

            formData.append('bnnr_id', this.banner.bnnr_id);

            this.attachments = [];
            document.getElementById('files').value = [];

            this.$store.dispatch('addImagensBannerPhoto', formData)
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Imagem adicionada com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.$emit('atualizar');
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        onFilesChange(e) {
            let selectedFiles = e.target.files || e.dataTransfer.files;

            if (!selectedFiles.length)
                return;

            for (let i = 0; i < selectedFiles.length; i++) {
                this.attachments.push(selectedFiles[i]);
            }

            this.onFilesSubmit();

        },
    },
    components: {

    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 500px;
}
</style>
